import React from "react";
import {IonAlert} from "@ionic/react";
import "./AssureAlert.scss";

interface AssureAlertProps {
    header: string;
    message: string;
    acceptText: string;
    declineText?: string;
    handleAccept: () => void;
    handleDecline?: () => void;
    isOpen: boolean;
    didDismiss?: () => void;
}

export const AssureAlert: React.FC<AssureAlertProps> = (props) => {
    const {
        handleAccept,
        handleDecline,
        isOpen,
        header,
        message,
        didDismiss,
        acceptText,
        declineText,
    } = props;

    return (
        <IonAlert
            isOpen={isOpen}
            onDidDismiss={didDismiss}
            header={header}
            animated={true}
            backdropDismiss={false}
            cssClass={"alert"}
            message={message}
            buttons={[
                {
                    text: declineText || '',
                    role: "cancel",
                    cssClass: "alert-button",
                    handler: handleDecline,
                },
                {
                    text: acceptText,
                    handler: handleAccept,
                    cssClass: "alert-button alert-button--bold",
                },
            ]}
        />
    );
};

export default AssureAlert;
