import {FilesystemDirectory, Plugins} from "@capacitor/core";
import {isPlatform} from "@ionic/react";
import {EmailComposer} from "@ionic-native/email-composer";
import {EMAIL_SERVICE_NOT_AVAILABLE, SERVICE_NOT_AVAILABLE} from "../consants/config";
import {showToastType} from "../types";
import {FileOpener} from "@ionic-native/file-opener";

const {Filesystem} = Plugins;

const forwardPdfToEmail = async (base64Pdf: string, showToastMessage: showToastType) => {
    const hasAccount = await EmailComposer.hasAccount().then((isValid) => isValid);
    const hasClient = await EmailComposer.hasClient().then((isValid) => isValid);

    console.log(hasAccount, hasClient);
    if (!hasAccount && !hasClient) {
        showToastMessage(EMAIL_SERVICE_NOT_AVAILABLE, "warning");
        return;
    }

    EmailComposer.isAvailable()
        .then(() => {
            let email = {
                attachments: [base64Pdf],
                subject: "",
                body: "",
                isHtml: true,
            };

            EmailComposer.open(email);
        })
        .catch(() => {
            showToastMessage(EMAIL_SERVICE_NOT_AVAILABLE, "warning");
        });
};

export function handleForwardPdfToEmail(getBase64Pdf: () => Promise<string>, showToastMessage: showToastType) {
    if (!isPlatform("cordova")) {
        return showToastMessage(SERVICE_NOT_AVAILABLE, "danger");
    }

    getBase64Pdf()
        .then((base64) => {
            let base64part = base64.split(",")[1];
            let bas64Attachment: string = "base64:" + generatePdfName() + "//" + base64part;

            forwardPdfToEmail(bas64Attachment, showToastMessage);
        })
        .catch((err) => {
            showToastMessage(err, "danger");
        });
}

const savePdfToFileSystem = (base64pdf: string, showToastMessage: showToastType) => {
    if (isPlatform("cordova")) {
        const fileName = generatePdfName();
        try {
            Filesystem.writeFile({
                path: fileName,
                data: base64pdf,
                directory: FilesystemDirectory.Documents,
            }).then((getUriResult) => {
                const path = getUriResult.uri;
                FileOpener
                    .open(path, 'application/pdf')
                    .catch(error => showToastMessage(`${SERVICE_NOT_AVAILABLE} ${error}`, "danger"));
            });
        } catch (error) {
            showToastMessage(`${SERVICE_NOT_AVAILABLE} ${error}`, "danger");
        }
    } else {
        const a = document.createElement('a');
        a.href = base64pdf;
        a.target = "_blank";
        a.download = generatePdfName();

        document.body.appendChild(a);
        a.click();
    }
};


export async function handleSavePdf(getBase64Pdf: () => Promise<string>, showToastMessage: showToastType) {
    /*if (!isPlatform("cordova")) {
        showToastMessage(
            SERVICE_NOT_AVAILABLE,
            "danger"
        );
        return;
    }*/

    let base64: string = await getBase64Pdf();
    savePdfToFileSystem(base64, showToastMessage);
}

const generatePdfName = () => {
    let date = new Date();
    let timestamp: string = date.toLocaleString().replace(/[\s/:.]/g, "_").replace(/,/g, "");
    return "Javaslat_" + timestamp + ".pdf";
};

export const openEmailClientOnWeb = (body: string) => {
    const a = document.createElement('a');
    a.href = `mailto:?subject=${generatePdfName()}&body=${body}`;
    a.target = "_blank";

    document.body.appendChild(a);
    a.click();
}
