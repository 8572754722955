import React, {useState} from "react";
import {Redirect, Route} from "react-router-dom";
import {IonApp, IonRouterOutlet} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router";
import LandingPage from "./container/LandingPage/LandingPage";
import PrivacyPage from "./container/PrivacyPage/PrivacyPage";
import Calculator from "./container/Calculator/Calculator";
import ToastMessage from "./components/ToastMessage/ToastMessage";
import "./App.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import {LOGGED_IN, STATEMENT_ACCEPTED} from "./utils/consants/config";
import InfoPage from "./container/InfoPage/InfoPage";
import {isPrivacyAccepted} from "./utils/functions/privacy";
import About from "./container/About/About";
import Login from "./container/Login/Login";

const App: React.FC = () => {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastColor, setToastColor] = useState("warning");

    const setToastClose = () => {
        setShowToast(false);
    };

    const showToastMessage = (message: string, color: string) => {
        setToastMessage(message);
        setToastColor(color);
        setShowToast(true);
    };

    const isStatementAccepted = () => Boolean(localStorage.getItem(STATEMENT_ACCEPTED));
    const isLoggedIn = () => Boolean(localStorage.getItem(LOGGED_IN));

    const isAuth = () => isStatementAccepted() && isPrivacyAccepted();

    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route
                        exact
                        path="/login"
                        render={(props) => {
                            if (isLoggedIn()) {
                                return <Redirect to="/about"/>;
                            }

                            return <Login/>;
                        }}
                    />

                    <Route
                        path="/info"
                        exact={true}
                        render={() => {
                            if (!isLoggedIn()) return <Login/>;
                            if (isAuth()) {
                                return <InfoPage/>;
                            }

                            return <Redirect to="/landing"/>;
                        }}
                    />

                    <Route
                        path="/landing"
                        exact={true}
                        render={() => {
                            if (!isLoggedIn()) return <Login/>;
                            if (isStatementAccepted()) {
                                return <Redirect to="/privacy"/>;
                            }

                            return <LandingPage/>;
                        }}
                    />

                    <Route
                        exact
                        path="/calculator"
                        render={(props) => {
                            if (!isLoggedIn()) return <Login/>;
                            if (!isPrivacyAccepted()) {
                                return <Redirect to="/privacy"/>;
                            }

                            return <Calculator showToastMessage={showToastMessage}/>;
                        }}
                    />

                    <Route
                        exact
                        path="/privacy"
                        render={(props) => {
                            if (!isLoggedIn()) return <Login/>;
                            if (!isStatementAccepted()) {
                                return <Redirect to="/landing"/>;
                            }

                            return <PrivacyPage/>;
                        }}
                    />

                    <Route
                        exact
                        path="/about"
                        render={(props) => {
                            if (!isLoggedIn()) return <Login/>;
                            if (!isPrivacyAccepted()) {
                                return <Redirect to="/privacy"/>;
                            }

                            return <About/>;
                        }}
                    />

                    <Route
                        exact
                        path="/"
                        render={(props) => {
                            if (!isLoggedIn()) return <Login/>;
                            if (isAuth()) {
                                return <Redirect to="/about"/>;
                            }

                            return <Redirect to="/landing"/>;
                        }}
                    />
                </IonRouterOutlet>
            </IonReactRouter>

            <ToastMessage
                isOpen={showToast}
                message={toastMessage}
                color={toastColor}
                setClose={setToastClose}
            />
        </IonApp>
    );
};

export default App;
