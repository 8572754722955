import {IonContent, IonPage, isPlatform} from "@ionic/react";
import React, {useState} from "react";
import "./LandingPage.scss";
import {STATEMENT_ACCEPTED} from "../../utils/consants/config";
import {Plugins} from "@capacitor/core";
import {useHistory} from "react-router";
import AssureAlert from "../../components/Alerts/AssureAlert";

const {App} = Plugins;

const LandingPage: React.FC = () => {
    const [showAlert, setShowAlert] = useState(true);
    const history = useHistory();

    const handleYes = () => {
        localStorage.setItem(STATEMENT_ACCEPTED, "true");
        history.push("/privacy");
    };

    const handleNo = () => {
        if (isPlatform("cordova")) {
            setShowAlert(false);
            App.exitApp();
        }
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="landing-page">
                    <img src="/assets/logo-heart.jpg" alt="Heart logo" className="heart"/>
                    <h1 className="title">P2Y12 ADP receptor gátló választás ACS-n átesett betegeknél</h1>
                    <img src="/assets/logo-mkt.png" alt="MKT logo" className="mkt-logo"/>
                    <div className="support">
                        <span>Támogató:</span>
                        <img src="/assets/astrazeneca_logo.png" alt="astrazeneca logo"/>
                    </div>
                </div>
            </IonContent>
            <AssureAlert
                acceptText={"Igen"}
                key={"assure-alert"}
                handleAccept={handleYes}
                header={"Nyilatkozat"}
                isOpen={showAlert}
                message={
                    "Az applikáció egészségügyi szakemberek számára készült. Kijelentem hogy egészségügyi szakember vagyok."
                }
                didDismiss={() => {
                }}
            />
        </IonPage>
    );
};

export default LandingPage;
