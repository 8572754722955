import {IonButton, IonContent, IonPage, IonText} from "@ionic/react";
import React from "react";
import {useHistory} from "react-router";
import Header from "../../components/Header/Header";
import "./InfoPage.scss";

const InfoPage: React.FC = () => {
    const history = useHistory();

    const handleRedirectBack = () => {
        history.push("/calculator");
    };
    const handleRedirectToPrivacy = () => {
        history.push("/privacy");
    };

    return (
        <IonPage>
            <Header title={"Névjegy"}/>
            <IonContent fullscreen className={"main ion-text-left"}>
                <div className={"section-big"}>
                    <IonText>
                        <p>
                            <b>Az alkalmazás üzemeltetője:</b>
                            <br/>
                            Magyar Kardiológusok Társasága Thrombosis Munkacsoport
                            <br/>
                            1146 Budapest, Szabó József u.12.
                            <br/>
                            Telefon: +36 (1) 461-0665 | 461-0666
                            <br/>
                            E-mail:&nbsp;
                            <a href="mailto:titkarsag@mkardio.org" className={"link"}>
                                titkarsag@mkardio.org
                            </a>
                        </p>

                        <p>
                            <b>
                                Az alkalmazás kifejlesztését az AstraZeneca Kft. támogatta, azonban annak működését
                                semmilyen formában
                                nem befolyásolta, a használata során semmilyen adat nem kerül a birtokába.
                            </b>
                            <br/>
                            AstraZeneca Kft.
                            <br/>
                            1117 Budapest, Alíz utca 4.
                            <br/>
                            Tel.: +36 1 883 6500
                            <br/>
                            Az AstraZeneca Kft. az általa forgalmazott készítmények alkalmazását csak az érvényes hazai
                            alkalmazási
                            előírásoknak megfelelően javasolja.
                            <br/>
                        </p>

                        <p>
                            v1.0 Utolsó frissítés időpontja: <span className="nowrap">2021.10.11.</span>
                            <br/>
                            © Magyar
                            Kardiológusok Társasága Thrombosis
                            Munkacsoport
                            2020.
                        </p>

                        <p>
                            Jelen alkalmazás tartalmát és működését a Magyar Kardiológusok Társasága Thrombosis
                            Munkacsoportja felügyeli. Az alkalmazás kizárólag egyészségügyi szakemberek számára készült.
                            Az alkalmazás által adott javaslat önmagában nem alkalmas az P2Y12 ADP receptor
                            gátló
                            terápia választással kapcsolatos döntés meghozatalára vagy annak értékelésére, minden
                            esetben szükséges a páciens egyedi szakorvosi értékelése. Az alkalmazás Magyar Kardiológusok
                            Társasága Thrombosis Munkacsoportjának „Trombocitaaggregáció-gátlás akut koronária
                            szindrómán átesett betegek magas kockázatú alcsoportjaiban” kezelési ajánlása alapján, a
                            hazai finanszírozási protokoll figyelembevételével készült. Az alkalmazás által adott
                            javaslattal kapcsolatban semmilyen felelősséget nem vállalunk!
                        </p>
                        <p>
                            Az alkalmazásban személyes adat nem kerül rögzítésre és tárolásra. A kérdésekre adott
                            válaszait, majd azok
                            alapján javasolt terápiához vezető algoritmus és a javaslat PDF formátumban menthető az Ön
                            igényeinek
                            megfelelően. A PDF dokumentum tárolása az Ön döntése szerint lehetséges, kérjük, minden
                            esetben vegye
                            figyelembe az{" "}
                            <span className={"link"} onClick={handleRedirectToPrivacy}>
                                adatkezelésre vonatkozó szabályokat.
              </span>
                            <br/><br/>
                            *Kiemelendő, hogy a 2018.10.15-én Magyar Közlönyben megjelent ﬁnanszírozási rendelet Eü70
                            24-es pontjának megfelelő módosított rendelkezésében az anamnézisben szereplő TIA/stroke
                            kontraindikációként van megjelölve egyaránt a prasugrel és ticagrelor esetén, nem
                            differenciálva az egyértelműen kontraindikált prasugrel és az alkalmazható ticagrelor
                            között. A szerzők véleménye, hogy ez a szakmailag hibás megfogalmazás a közeljövőben
                            korrekcióra kerülhet, hiszen már jelenleg is konﬂiktusban áll a 2018.10.15-től hatályos
                            35/2018. (X. 12.) EMMI-rendelettel, amelyben a ticagrelor esetén az anamnézisben szereplő
                            korábbi stroke/TIA nem szerepel kontraindikációként az ACS-es betegek megjelölt típusainál.
                        </p>
                    </IonText>
                </div>
            </IonContent>
            <div className={"bottom-buttons-container"}>
                <div className=" section__inner ion-text-center">
                    <>
                        <IonButton className={"p-button"} onClick={handleRedirectBack}>
                            Vissza
                        </IonButton>
                    </>
                </div>
            </div>
        </IonPage>
    );
};

export default InfoPage;
