import {IonToast} from "@ionic/react";
import React from "react";

interface ToastMessageProps {
    message: string;
    color?: string;
    isOpen: boolean;
    setClose: () => void;
}

const ToastMessage: React.FC<ToastMessageProps> = (props) => {
    const {
        isOpen,
        message,
        color,
        setClose
    } = props;

    return (
        <IonToast
            isOpen={isOpen}
            onDidDismiss={() => setClose()}
            message={message}
            position="bottom"
            color={color ?? "danger"}
            duration={5000}
            buttons={[
                {
                    side: "end",
                    text: "Ok",
                    role: "cancel",
                    handler: () => {
                        console.log("Cancel clicked");
                    },
                },
            ]}
        />
    );
};

export default ToastMessage;
