import React, {useState} from "react";
import {IonIcon, IonText} from "@ionic/react";
import "./Question.scss";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import AnswerButtons from "../AnswerButtons/AnswerButtons";
import {informationCircleOutline} from "ionicons/icons";
import {QuestionInterface} from "../../utils/interfaces";
import Popup from "../Popup/Popup";

interface QuestionTextProps {
    question: QuestionInterface;
    handleAnswerClick: (answerText: string) => void;
}

const Question: React.FC<QuestionTextProps> = ({question, handleAnswerClick}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <div className="section">
            <div className={"ion-text-center question-title"}>
                <IonText>Kérjük, feleljen az alábbi kérdésre.</IonText>
            </div>

            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={question.question}
                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                    classNames="fade"
                    timeout={2000}
                >
                    <div className="question-text" onClick={() => question.popupText && setIsPopupOpen(true)}>
                        <IonText>{question.question}</IonText>
                        {question.popupText && <IonIcon color={'danger'} className='question-icon' icon={informationCircleOutline}/>}
                    </div>
                </CSSTransition>
            </SwitchTransition>

            {question.popupText && <Popup text={question.popupText} isOpen={isPopupOpen} popupClose={setIsPopupOpen}/>}

            <AnswerButtons handleAnswerClick={handleAnswerClick}/>
        </div>
    );
};

export default Question;
