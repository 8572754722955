import React from "react";
import {IonModal, IonButton} from "@ionic/react";
import "./Popup.scss";

interface PopupProps {
    text: string;
    popupClose: any;
    isOpen: boolean;
}

const Popup: React.FC<PopupProps> = ({text, isOpen, popupClose}) => {
    if (!text) {
        return null;
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => popupClose(false)}>
            <div className='popup'>
                <p dangerouslySetInnerHTML={{__html: text}}/>
                <IonButton className={"p-button popup-button"} onClick={() => popupClose(false)}>Bezárás</IonButton>
            </div>
        </IonModal>
    );
};

export default Popup;
