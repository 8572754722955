import React, {useState} from 'react';
import {IonFab, IonFabButton, IonIcon, IonLabel} from "@ionic/react";
import "./ActionButtons.scss";
import {handleSavePdf, openEmailClientOnWeb} from "../../utils/functions/PdfHandler";
import {downloadOutline, mailOutline, reloadOutline} from "ionicons/icons";
import AssureAlert from "../../components/Alerts/AssureAlert";
import {useHistory} from "react-router";
import {showToastType} from "../../utils/types";

interface ActionButtonsProps {
    hasSuggestion: boolean;
    canReset: boolean;
    showToastMessage: showToastType;
    actions: any;
}

const ActionButtons: React.FC<ActionButtonsProps> = (props) => {
    const {
        hasSuggestion,
        canReset,
        showToastMessage,
        actions
    } = props;
    const history = useHistory();
    const [alertReset, setAlertReset] = useState<boolean>(false);

    // @ts-ignore
    return (
        <>
            <IonFab horizontal="end" vertical="bottom" slot="fixed" className={"fab-container"}>
                {hasSuggestion && (
                    <IonFabButton
                        className="ion-float-left fab-button"
                        onClick={() => handleSavePdf(actions.generateBase64Pdf, showToastMessage)}
                    >
                        <IonIcon icon={downloadOutline}/>
                    </IonFabButton>
                )}

                {hasSuggestion && (
                    <IonFabButton
                        className="ion-float-left fab-button"
                        onClick={() => openEmailClientOnWeb(actions.generateEmailBodyOnWeb())}
                    >
                        <IonIcon icon={mailOutline}/>
                    </IonFabButton>
                )}

                {canReset && (
                    <IonFabButton
                        className="ion-float-left fab-button "
                        onClick={() => {
                            setAlertReset(true);
                        }}
                    >
                        <IonIcon icon={reloadOutline}/>
                    </IonFabButton>
                )}
            </IonFab>

            <IonFab horizontal="start" vertical="bottom" slot="fixed" className={"fab-container"}>
                <IonFabButton
                    color="light"
                    className="ion-float-left  fab-button fab-button--info"
                    onClick={() => history.push("/info")}
                >
                    <IonLabel>i</IonLabel>
                </IonFabButton>
            </IonFab>
            <AssureAlert
                acceptText={"Újrakezdés"}
                declineText={"Mégsem"}
                header={"Biztosan újra szeretné kezdeni?"}
                message={""}
                handleAccept={actions.handleReset}
                handleDecline={() => {
                }}
                isOpen={alertReset}
                didDismiss={() => setAlertReset(false)}
            />
        </>
    );
};

export default ActionButtons;
