import React from "react";
import {Page, Text, View, Document, StyleSheet} from "@react-pdf/renderer";
import {QuestionHistoryInterface} from "../../utils/interfaces";

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 12,
    },
    section: {
        margin: 5,
        padding: 5,
    },
    finalSuggestion: {
        fontSize: 16,
        fontWeight: "bold",
    },
    suggestion: {
        color: "#b30f1f",
        fontStyle: "italic",
    },
});

interface ResultPdfComponentProps {
    questionHistory: QuestionHistoryInterface[];
    suggestion: string;
}

const ResultPdfComponent: React.FC<ResultPdfComponentProps> = (props) => {
    const {
        questionHistory,
        suggestion,
    } = props;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.finalSuggestion}>Javaslat: {suggestion}</Text>
                </View>
                {questionHistory.map((question) => (
                    <View key={question.questionId} style={styles.section}>
                        <Text>{question.questionText}</Text>
                        <Text>
                            {" > "}
                            {question.answer ? "Igen" : "Nem"}
                        </Text>
                        {question.suggestionText && (
                            <Text style={styles.suggestion}>{question.suggestionText}</Text>
                        )}
                    </View>
                ))}
            </Page>
        </Document>
    );
};

export default ResultPdfComponent;
