import React from 'react';
import {IonButton, IonContent, IonPage} from "@ionic/react";
import './About.scss';
import {useHistory} from "react-router";

const About: React.FC = () => {
    const history = useHistory();

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="landing-page">
                    <img src="/assets/logo-heart.jpg" alt="Heart logo" className="heart"/>
                    <h1 className="title" style={{fontSize: '1.3rem', lineHeight: '1.7rem'}}>
                        P2Y12 ADP receptor gátló választása ACS esetén a magyar társadalombiztosítási támogatási
                        szabályok figyelembevételével.
                    </h1>
                    <div className="logo">
                        <span>Támogató:</span>
                        <img src="/assets/astrazeneca_logo.png" alt="astrazeneca logo"/>
                    </div>
                    <div className={"bottom-buttons-container bottom-buttons-container--transparent"}>
                        <div className="button-holder section__inner-full-width ion-text-center">
                            <IonButton className={"p-button p-button--secondary"}
                                       onClick={() => history.push('/calculator')}>
                                Tovább
                            </IonButton>
                        </div>

                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default About;
