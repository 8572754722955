import React, {useState} from "react";
import {IonText} from "@ionic/react";
import "./Suggestion.scss";
import Popup from "../Popup/Popup";
import {SuggestionInterface} from "../../utils/interfaces";

interface SuggestionProps {
    suggestion: SuggestionInterface;
}

const Suggestion: React.FC<SuggestionProps> = ({suggestion}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    return (
        <div className="section">
            <div className={"ion-text-center s-title"}>
                <IonText>
                    P2Y12 ADP receptor gátló
                    választás ACS-n átesett betegeknél
                </IonText>
            </div>

            <div className={"suggestion-result-box"}>
                <div className={"suggestion-result-box__inner"} onClick={() => setIsPopupOpen(true)}>
                    <IonText>{suggestion.suggestion}</IonText>
                    <span className={"extra-info"}>i</span>
                </div>
            </div>

            <Popup text={suggestion.popupText} isOpen={isPopupOpen} popupClose={setIsPopupOpen}/>
        </div>
    );
};

export default Suggestion;
