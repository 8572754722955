import React, {useState} from 'react';
import {IonButton, IonContent, IonInput, IonLabel, IonPage} from "@ionic/react";
import {useHistory} from "react-router";
import {LOGGED_IN} from "../../utils/consants/config";
import classes from "./Login.module.scss";

const Login = () => {
    const history = useHistory();
    const [loginValue, updateLoginValue] = useState<string | undefined | null>('');
    const [hasError, updateHasError] = useState(false);

    const onLogin = () => {
        console.log(loginValue);
        if (loginValue === 'acs-p2y12') {
            localStorage.setItem(LOGGED_IN, "true");
            history.push("/landing");
        } else {
            updateHasError(true);
        }
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="landing-page">
                    <img src="/assets/logo-heart.jpg" alt="Heart logo" className="heart"/>
                    <h1 className="title">P2Y12 ADP receptor gátló választás ACS-n átesett betegeknél</h1>
                    <div className={classes['login']}>
                        <IonLabel position="stacked">Bejelentkezés</IonLabel>
                        <IonInput value={loginValue} placeholder={"Jelszó"}
                                  onIonChange={e => updateLoginValue(e.detail.value)}/>
                        {!!hasError && <p>Hibás jelszó!</p>}
                    </div>
                    <div className={"bottom-buttons-container bottom-buttons-container--transparent"}>
                        <div className="button-holder section__inner-full-width ion-text-center">
                            <IonButton className={"p-button p-button--secondary"}
                                       onClick={onLogin}>
                                Tovább
                            </IonButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;
