import {IonButton, IonContent, IonPage, IonText, isPlatform} from "@ionic/react";
import React from "react";
import {useHistory} from "react-router";
import {Plugins} from "@capacitor/core";
import {PRIVACY_ACCEPTED} from "../../utils/consants/config";
import Header from "../../components/Header/Header";
import "./PrivacyPage.scss";
import {isPrivacyAccepted} from "../../utils/functions/privacy";

const {App} = Plugins;

const PrivacyPage: React.FC = () => {
    const history = useHistory();

    const handleAccept = () => {
        localStorage.setItem(PRIVACY_ACCEPTED, "true");
        history.push("/about");
    };

    const handleRedirectBack = () => {
        history.push("/info");
    };

    const handleReject = () => {
        localStorage.setItem(PRIVACY_ACCEPTED, "false");
        if (isPlatform("cordova")) {
            App.exitApp();
        }
    };

    const controlButtons = () => {
        if (!isPrivacyAccepted()) {
            return (
                <>
                    <IonButton className={"p-button"} onClick={handleAccept}>
                        Elfogadom
                    </IonButton>
                    {!isPlatform("cordova") && (
                        <IonButton className={"p-button p-button--secondary"} onClick={handleReject}>
                            Nem fogadom el
                        </IonButton>
                    )}
                </>
            );
        }

        return (
            <IonButton className={"p-button "} onClick={handleRedirectBack}>
                Vissza
            </IonButton>
        );
    };

    return (
        <IonPage>
            <Header title={"Adatkezelési Tájékoztató"}/>
            <IonContent fullscreen className={"main"}>
                <div className={"section-big"}>
                    <IonText>
                        A Magyar Kardiológusok Társasága, mint adatkezelő vállalja, hogy az adatokkal való visszaélések
                        megelőzése érdekében az Internetes regisztráció során kezelésébe került személyes adatokat az
                        érintett személy beleegyezése nélkül harmadik fél számára semmilyen körülmények között nem adja
                        ki, valamint nem teszi közzé.
                        <br/><br/>
                        Amennyiben a regisztrációs űrlapot (on-line, vagy a papír-verziót egyaránt) a társaság tagságába
                        történő jelentkezés céljából tölti ki valaki, és ezzel tagságra jelentkezik, úgy egyúttal
                        jóváhagyását adja, hogy megadott adatait a Magyar Kardiológusok Társasága - kizárólag a Társaság
                        működése, működtetése, valamint szakmai rendezvényekről történő tájékoztatás, illetve egyéb
                        szakmai információk, szakmai anyagok eljuttatása céljából - használhatja.
                        <br/><br/>
                        A Magyar Kardiológusok Társasága Web-rendszerében megadott adatokat a [ www.doki.NET ], illetve
                        az azt üzemeltető szervezet, mint informatikai háttér-szolgáltató kezeli. A Magyar Kardiológusok
                        Társasága és a doki.NET adatvédelmi kötelezettségei a hozzá beérkezett, valamint a szerverein
                        tárolt adatokra és azok kezelésére vonatkoznak.
                    </IonText>
                </div>
            </IonContent>

            <div className={"bottom-buttons-container"}>
                <div className="button-holder section__inner-full-width ion-text-center">
                    {controlButtons()}
                </div>
            </div>
        </IonPage>
    );
};

export default PrivacyPage;
