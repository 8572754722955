import {
    AnswerInterface,
    AnswerRecordInterface,
    QuestionInterface, QuestionRecordInterface,
    SuggestionInterface,
    SuggestionRecordInterface
} from "../interfaces";

const suggestions = require("../../store/static/suggestions.json");
const questions = require("../../store/static/questions.json");
const answers = require("../../store/static/answers.json");

export let questionsMap: Map<number, QuestionInterface> = new Map([]);
export let suggestionsMap: Map<number, SuggestionInterface> = new Map([]);
export let answersMap: Map<number, AnswerInterface> = new Map([]);

let suggestionList: Array<SuggestionRecordInterface> = suggestions.suggestions;
let answerList: Array<AnswerRecordInterface> = answers.answers;
let questionList: Array<QuestionRecordInterface> = questions.questions;

suggestionList.forEach(s => {
    const suggestion: SuggestionInterface = {
        nextQuestionId: s.nextQuestionId,
        suggestion: s.suggestion,
        popupText: s.popupText
    };
    suggestionsMap.set(s.suggestionId, suggestion);
});

answerList.forEach(a => {
    const answer: AnswerInterface = {
        answer: a.answer,
        nextId: a.nextId,
        nextType: a.nextType,
        questionId: a.questionId
    };
    answersMap.set(a.answerId, answer);
});

questionList.forEach(q => {
    const question: QuestionInterface = {question: q.question, popupText: q.popupText};
    questionsMap.set(q.questionId, question);
});
