export const STATEMENT_ACCEPTED = "STATEMENT_ACCEPTED";
export const PRIVACY_ACCEPTED = "PRIVACY_ACCEPTED";
export const SERVICE_NOT_AVAILABLE = "Ez a funkció jelenleg nem elérhető!";
export const EMAIL_SERVICE_NOT_AVAILABLE = "Ez a funkció jelenleg nem elérhető! Lehetséges, hogy nincs email felhasználó vagy email kliens beállítva az eszközön.";
export const LOGGED_IN = "LOGGED_IN";






