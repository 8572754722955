import React from "react";
import {
    IonHeader, IonToolbar, IonTitle,
} from "@ionic/react";
import "./Header.scss"

interface HeaderProps {
    title: string
}

const Header: React.FC<HeaderProps> = ({title}) => {
    return (
        <IonHeader className="ion-text-center">
            <IonToolbar className="header">
                <IonTitle className={"header__title"}>{title}</IonTitle>
            </IonToolbar>
        </IonHeader>
    );
};

export default Header;
