import {IonContent, IonPage, IonRow} from "@ionic/react";
import React, {useRef, useState} from "react";
import {pdf} from "@react-pdf/renderer";
import ResultPdfComponent from "../../components/ResultPdf/ResultPdfComponent";
import Header from "../../components/Header/Header";
import Question from "../../components/Question/Question";
import "./Calculator.scss";
import QuestionAndAnswer from "../../components/QuestionAndAnswer/QuestionAndAnswer";
import Suggestion from "../../components/Suggestion/Suggestion";
import {answersMap, questionsMap, suggestionsMap,} from "../../utils/functions/DataProvider";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import ActionButtons from "../ActionButtons/ActionButtons";
import {calculatorNodeType, showToastType} from "../../utils/types";
import {
    AnswerInterface,
    QuestionHistoryInterface,
    QuestionInterface,
    QuestionRecordInterface,
    SuggestionInterface
} from "../../utils/interfaces";

interface CalculatorProps {
    showToastMessage: showToastType;
}

const Calculator: React.FC<CalculatorProps> = ({showToastMessage}) => {
    const historyEnd = useRef<HTMLDivElement>(null);
    const [suggestion, setSuggestion] = useState<SuggestionInterface | undefined>();
    const [questionHistory, setQuestionHistory] = useState<QuestionHistoryInterface[]>([]);
    const [showSuggestion, setShowSuggestion] = React.useState(false);
    const firstQuestion: QuestionRecordInterface = {
        questionId: 1,
        question: questionsMap.get(1)!.question,
        popupText: questionsMap.get(1)!.popupText
    };
    const [question, setQuestion] = useState<QuestionRecordInterface | undefined>(firstQuestion);

    async function generateBase64Pdf(): Promise<string> {
        const component = <ResultPdfComponent suggestion={suggestion!.suggestion} questionHistory={questionHistory}/>;
        const blob = await pdf(component).toBlob();
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                const base64 = reader.result;
                if (base64 && typeof base64 === "string") {
                    return resolve(base64);
                }

                reject("Error: pdf blob to base64");
            };
        });
    }

    const generateEmailBodyOnWeb = () => {
        return (
            `Javsalat: ${encodeURIComponent(suggestion!.suggestion)}%0A%0A${questionHistory.map((question: any) => (
                `${encodeURIComponent(question.questionText)}%0A > ${question.answer ? "Igen" : "Nem"} %0A${question.suggestionText ? encodeURIComponent(question.suggestionText) : ''}%0A%0A`)).join('')}`
        )
    }

    const addToQuestionHistory = (qna: QuestionHistoryInterface) => {
        if (!questionHistory) {
            setQuestionHistory(() => [qna]);
            return
        }

        setQuestionHistory((questionHistory) => [...questionHistory, qna]);
    };

    const handleReset = () => {
        setSuggestion(undefined);
        setShowSuggestion(false);
        setQuestion(firstQuestion);
        setQuestionHistory([]);
    };

    const handleJumpToQuestion = (questionId: number, suggestionText: string | undefined, popupText: string) => {
        setSuggestion(undefined);
        setShowSuggestion(false);

        if (suggestionText) {
            setSuggestion({nextQuestionId: questionId, suggestion: suggestionText, popupText});
        }

        const question: QuestionInterface | undefined = questionsMap.get(questionId);
        const questionRecord: QuestionRecordInterface = {
            questionId: questionId,
            question: question!.question,
            popupText: question!.popupText
        };
        setQuestion(questionRecord);

        const jumpedIndex = questionHistory?.findIndex((qna) => qna.questionId === questionId)!;
        setQuestionHistory(questionHistory?.slice(0, jumpedIndex));
    };

    const handleAnswerClick = (answerText: string) => {
        let questionHistory: QuestionHistoryInterface = {
            questionText: question!.question,
            answer: answerText === "Igen",
            questionId: question!.questionId,
            suggestionText: undefined,
            popupText: question!.popupText ?? ''
        };

        const values = Array.from(answersMap.values());
        const answer: AnswerInterface | undefined = values.find(
            (a) => a.questionId === question!.questionId && a.answer === answerText
        );

        if (!answer) {
            return;
        }

        if (answer!.nextType === calculatorNodeType.Question) {
            const nextQuestionData: QuestionInterface | undefined = questionsMap.get(answer.nextId);
            setSuggestion(undefined);
            setShowSuggestion(false);

            if (!nextQuestionData) {
                return;
            }

            const nextQuestion: QuestionRecordInterface = {
                questionId: answer.nextId,
                question: nextQuestionData!.question,
                popupText: nextQuestionData!.popupText
            };
            setQuestion(nextQuestion);
        } else if (answer!.nextType === calculatorNodeType.Suggestion) {
            const suggestion: SuggestionInterface | undefined = suggestionsMap.get(answer.nextId);

            if (!suggestion) {
                return;
            }

            setSuggestion(suggestion!);

            if (suggestion.nextQuestionId > 0) {
                let nextQuestionData: QuestionInterface | undefined = questionsMap.get(suggestion.nextQuestionId);

                if (!nextQuestionData) {
                    return;
                }

                let nextQuestion: QuestionRecordInterface = {
                    questionId: suggestion.nextQuestionId,
                    question: nextQuestionData!.question,
                    popupText: nextQuestionData!.popupText
                };
                setQuestion(nextQuestion);
                questionHistory.suggestionText = suggestion.suggestion;
                questionHistory.popupText = suggestion.popupText;
            } else {
                setQuestion(undefined);
                setShowSuggestion(true);
            }
        } else {
            return; //if the next type cant be resolved as known type;
        }

        addToQuestionHistory(questionHistory);
        setTimeout(() => historyEnd.current?.scrollIntoView({behavior: "smooth"}), 200);
    };

    console.log(question);

    return (
        <IonPage>
            <Header title={"Kalkulátor"}/>
            <IonContent fullscreen className={"main"} forceOverscroll={false}>
                <div className="background-heart"/>

                {suggestion && !question &&
                <CSSTransition in={showSuggestion} timeout={500} classNames="grow">
                    <Suggestion suggestion={suggestion!}/>
                </CSSTransition>}

                {question &&
                <Question question={question} handleAnswerClick={handleAnswerClick}/>}

                {questionHistory && questionHistory.length > 0 &&
                <IonRow className={"history-container"}>
                    <TransitionGroup className="list">
                        {questionHistory.map((qna) => (
                            <CSSTransition key={qna.questionId} timeout={500} classNames="fade">
                                <QuestionAndAnswer
                                    questionHistoryObj={qna}
                                    handleJumpToQuestion={handleJumpToQuestion}
                                />
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                    <div ref={historyEnd}/>
                </IonRow>}
            </IonContent>

            <ActionButtons hasSuggestion={!!suggestion} canReset={questionHistory!.length > 0}
                           showToastMessage={showToastMessage}
                           actions={{generateBase64Pdf, handleReset, generateEmailBodyOnWeb}}/>
        </IonPage>
    );
};

export default Calculator;
