import React, {useState} from "react";
import {IonCol, IonIcon, IonRow, IonText} from "@ionic/react";
import {checkmarkOutline, closeOutline} from "ionicons/icons";
import "./QuestionAndAnswer.scss";
import AssureAlert from "../Alerts/AssureAlert";
import {QuestionHistoryInterface} from "../../utils/interfaces";

export interface QuestionAndAnswerProps {
    questionHistoryObj: QuestionHistoryInterface;
    handleJumpToQuestion: (
        questionId: number,
        suggestionText: string | undefined,
        popupText: string
    ) => void;
}

const QuestionAndAnswer: React.FC<QuestionAndAnswerProps> = ({questionHistoryObj, handleJumpToQuestion}) => {
    const [alertJump, setAlertJump] = useState<boolean>(false);

    return (
        <div className={"qna-container"}>
            <IonRow className={"align-center"} onClick={() => setAlertJump(true)}>
                <IonCol size={"11"}>
                    <IonText className={"qna-container__question"}>
                        {questionHistoryObj.questionText}
                    </IonText>
                    {questionHistoryObj.suggestionText && (
                        <IonRow className="suggestion-text">
                            <IonText>{questionHistoryObj.suggestionText}</IonText>
                        </IonRow>
                    )}
                </IonCol>

                <IonCol size={"1"}>
                    {questionHistoryObj.answer ? (
                        <IonIcon
                            className={"ion-float-right icon-answer icon-answer--check"}
                            icon={checkmarkOutline}
                        />
                    ) : (
                        <IonIcon
                            className={"ion-float-right icon-answer icon-answer--close"}
                            icon={closeOutline}
                        />
                    )}
                </IonCol>
            </IonRow>

            <AssureAlert
                acceptText={"Módosítás"}
                declineText={"Mégsem"}
                header={"Módosítja a válaszát erre a kérdésre?"}
                message={'"' + questionHistoryObj.questionText + '"'}
                handleAccept={() => {
                    handleJumpToQuestion(questionHistoryObj.questionId, questionHistoryObj.suggestionText, questionHistoryObj.popupText);
                }}
                handleDecline={() => {
                    setAlertJump(false);
                }}
                isOpen={alertJump}
                didDismiss={() => {
                }}
            />
        </div>
    );
};

export default QuestionAndAnswer;
