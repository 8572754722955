import React from 'react';
import {IonButton, IonRow} from "@ionic/react";
import "./AnswerButtons.scss";

interface AnswerButtonsProps {
    handleAnswerClick: (answerText: string) => void;
}

const AnswerButtons: React.FC<AnswerButtonsProps> = ({handleAnswerClick}) => (
    <IonRow className="answer-buttons">
        <IonButton
            className={"answer-button"}
            onClick={() => handleAnswerClick("Igen")}
        >
            Igen
        </IonButton>
        <IonButton
            className={"answer-button answer-button--secondary "}
            onClick={(e) => handleAnswerClick("Nem")}
        >
            Nem
        </IonButton>
    </IonRow>
);

export default AnswerButtons;